.swiper {
  width: 100%; /* Subtract the total 'padding' you want from 100% width */
  height: auto; 
  background-color: #fff;
}

.swiper-slide {
  height: auto;
  box-sizing: border-box;
}

.title {
  font-size: 1.625rem;
  padding: 0;
  margin: 40px 0 25px 0;
}

.swiper-button-next {
  background-image: url('../../assets/chevron-right.svg');
} 
.swiper-button-prev {
  background-image: url('../../assets/chevron-left.svg'); 
}

.swiper-button-next, .swiper-button-prev {
  background-color: #fff; 
  border: .5px solid #ebebeb; 
  border-radius: 50%; 
  width: 35px; 
  height: 35px; 
  display: flex; 
  align-items: center; 
  justify-content: center; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%; /* Make sure the chevron image is appropriately sized */
  box-shadow: 0 2px 2px 0 #0000003d, 0 0 2px 0 #0000001f;
  opacity: 92%;
  top: 50%; /* Vertically center the buttons */
  transform: translateY(-50%); /* Ensure buttons stay centered */
  &:hover {
    opacity: 100%;
  }
}

@media(max-width: 768px) {
  .swiper {
    width: calc((100% - 20px)); /* Adjust the width for mobile */
    margin: 0 10px;
  }
  .title {
    padding: 0;
    margin: 20px 0 10px 10px;
  }
  .swiper .swiper-button-next, 
  .swiper .swiper-button-prev {
    display: none !important;
  }
}

.swiper {
  width: 100%; /* Subtract the total 'padding' you want from 100% width */
  height: auto; 
  background-color: #fff;
}

.swiper-slide {
  height: auto;
  box-sizing: border-box;
}
